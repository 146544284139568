import Vue from "vue";
const axios = require("axios");
import * as validation from "../global/validation";

export default Vue.extend({
    name: "applyForm",
    props: ["dialoges", "tribType", "tribName", "tribcolor"],
    data: () => ({
        el: 1,

        questions: [
            {
                num: 1,
                qeus:
                    "As a part of this community, you will gain access to the trade secrets & business tested insights of 1,000+ founders. Can you keep this information confidential?",
                desc:
                    "COM members share so freely because they trust in the other COM members. You too can share insights/results/challenges freely knowing your privacy is respected & valued.",
                choices: ["Yes", "No"],
                answer: "",
                type: "",
                trib: "all",
            },
            {
                num: 2,
                qeus:
                    "Do you agree to openly share your biggest challenges so the group can offer insights& support?",
                desc:
                    "When COM members log on, we leave our egos at the door. We share our challenges openly and humbly to shortcut the feedback process and get the answers we need, asap. Do you share this value?",
                choices: ["Yes", "No"],
                answer: "",
                type: "",
                trib: "all",
            },
            {
                num: 3,
                qeus:
                    "In COM, all founders help one another with their struggles & challenges. Are we willing to help others with your own advice & experience?",
                desc:
                    "We all share our biggest challenges and offer our best advice. This is the core value of COM that makes our community vibrant and strong.",
                choices: ["Yes", "No"],
                answer: "",
                type: "",
                trib: "all",
            },
            {
                num: 4,
                qeus:
                    "Do you promise to use the knowledge gained from this community to take fast action often?",
                desc: "Advice is worthless without action. Are you an action taker?",
                choices: ["Yes", "No"],
                answer: "",
                type: "",
                trib: "all",
            },
            {
                num: 5,
                qeus: "What is your first name?",
                desc: "",
                choices: [],
                answer: "",
                type: "text",
                trib: "all",
            },
            {
                num: 6,
                qeus: "What is your last name?",
                desc: "",
                choices: [],
                answer: "",
                type: "text",
                trib: "all",
            },
            {
                num: 7,
                qeus: "What is your email address?",
                desc: "",
                choices: [],
                answer: "",
                type: "email",
                trib: "all",
            },
            {
                num: 8,
                qeus: "What is your website address?",
                desc: "",
                choices: [],
                answer: "",
                type: "text",
                trib: "all",
            },
            {
                num: 9,
                qeus:
                    "If you are selected, we will need to do a phone interview before you can be approved. What is the best cell phone number to reach you on?",
                desc: "",
                choices: [],
                answer: "",
                type: "number",
                trib: "all",
            },
            {
                num: 10,
                qeus: "What type of business are you?",
                desc: "",
                choices: ["Digital services/agency", "SaaS", "E-commerce", "Other"],
                answer: "",
                type: "",
                trib: "all",
            },
            {
                num: 11,
                qeus: "What does your business do?",
                desc: "",
                choices: [],
                answer: "",
                type: "text",
                trib: "business",
            },
            {
                num: 12,
                qeus: "What is your current monthly revenue?",
                desc: "",
                choices: [],
                answer: "",
                type: "text",
                trib: "business",
            },
            {
                num: 13,
                qeus: "What is your revenue goal for this year?",
                desc: "",
                choices: [],
                answer: "",
                type: "text",
                trib: "business",
            },
            {
                num: 14,
                qeus: "What do you do ?",
                desc: "",
                choices: [],
                answer: "",
                type: "text",
                trib: "life",
            },
            {
                num: 15,
                qeus: "what is your 2022 main goal ?",
                desc: "",
                choices: [],
                answer: "",
                type: "text",
                trib: "life",
            },
            {
                num: 16,
                qeus:
                    "What is the first thing you want to achieve as a member of the COM community",
                desc: "",
                choices: [],
                answer: "",
                type: "text",
                trib: "all",
            },
            {
                num: 17,
                qeus:
                    "The last question, you understand that - after your application is reviewed- you will be required to do a phone or zoom interview. After the interview, if you are approved, a monthly membership will be due.",
                desc:
                    "A membership fee ensures that the only people joining convergence of minds are those who see the incredible value & opportunity ahead. It weeds out those who are not as serious or committed to transforming their life and /or business. It also covers our community success manager, who acts as your concierge and coach in helping you achieve the best out of COM journey. Yes, you get a private coach.",
                choices: [
                    "Yes, I understand -- this helps maintain the quality of the community.",
                    "No thanks -- l prefer to be a part of the ground where anyone can join & offer 'advice'.",
                ],
                answer: "",
                type: "",
                trib: "all",
            },
        ],
        applyLoading: false,
        // validation
        validation: validation.validation,
        Valid: false,
        notValid: false,
        // handle response messages
        success: false,
        error: false,
        response: false,
        text: "",
    }),

    methods: {
        apply: async function () {
            let that = this,
                str = "",
                arr = [],
                form = (this as any).$refs.applyForm as HTMLFormElement;

            this.notValid = false;
            form.validate();
            if (this.Valid) {
                that.applyLoading = true;

                // specify trib type
                arr =
                    this.tribType == "Life Tribe"
                        ? this.questions.filter((x: any) => x.trib == "life" || x.trib == "all")
                        : this.questions.filter((x: any) => x.trib == "business" || x.trib == "all");

                str = this.tribName ?
                    "type :" +
                    this.tribType +
                    " - " +
                    "name :" +
                    this.tribName +
                    " - " +
                    arr.map((x: any, i: number) =>
                        (x = `(question (${i + 1}) : ${x.qeus} - answer : ${x.answer})`)
                    ).join(" , ") : "type :" +
                    this.tribType +
                    " - " +
                    arr.map((x: any, i: number) => (x = `(question (${i + 1}) : ${x.qeus} - answer : ${x.answer})`)
                    ).join(" , ");

                let obj = Object.assign(
                    {},
                    {
                        email: "h.saber@tbc-me.com",
                        name: "convergence of minds",
                        subject: "Apply form",
                        content: str,
                    }
                );

                axios({
                    method: "POST",
                    url:
                        "https://landlord-bl-staging.azurewebsites.net/api/sixBeesEmailsCollector/createEmail",
                    data: obj,
                    headers: {
                        "Content-Type": "application/json",
                    },
                })
                    .then((response: any) => {
                        if (response.data.ok) {
                            that.response = true;
                            that.error = false;
                            that.success = true;
                            that.text = "Done ... ";
                            that.applyLoading = false;
                        } else {
                            that.response = true;
                            that.success = false;
                            that.error = true;
                            that.text = response.data.errorMessage;
                            that.applyLoading = false;
                        }
                    })
                    .catch((error: any) => {
                        console.log(error);
                    });
            } else {
                this.notValid = true;
                //console.log(this.$refs.applyForm)
            }
        },
    },
});
