<template>
  <div class="navBar" style="z-index: 99999999999999999999999999999999999999">
    <v-app-bar fixed class="py-0 elevation-4" height="80px" color="darkColor">
      <v-container class="px-0">
        <v-row align="center" justify="center" class="mx-0 px-0">
          <v-col sm="1">
            <v-toolbar-title>
              <v-img
                :contain="true"
                :src="require('../assets/imgs/logo.png')"
                max-width="70"
                max-height="70"
              ></v-img>
            </v-toolbar-title>
          </v-col>
          <v-col sm="11" class="d-none d-md-block">
            <v-tabs
              class="whiteColor--text float-right"
              background-color="transparent"
              mobile-breakpoint="991"
              right
            >
              <v-tabs-slider color="greenColor"></v-tabs-slider>
              <v-tab :link="true" to="/" class="text-uppercase whiteColor--text"
                >Home
              </v-tab>
              <v-tab :link="true" to="/team" class="text-uppercase whiteColor--text"
                >Team
              </v-tab>
              <v-tab :link="true" to="/conOfMind" class="text-uppercase whiteColor--text"
                >Convergence of Mind</v-tab
              >
              <v-tab :link="true" to="/workshops" class="text-uppercase whiteColor--text"
                >Workshops</v-tab
              >
            </v-tabs>
          </v-col>
          <v-col sm="11" class="d-block d-md-none float-right text-right">
            <v-app-bar-nav-icon
              class="greenColor--text elevation-5"
              @click.stop="drawer = !drawer"
            ></v-app-bar-nav-icon>
          </v-col>
        </v-row>
      </v-container>
    </v-app-bar>

    <v-navigation-drawer v-model="drawer" fixed top temporary color="darkColor">
      <v-list justify="center" align="center" nav dense>
        <v-list-item-group v-model="group" active-class="greenColor darkColor--text">
          <v-list-item class="py-3">
            <v-list-item-title class="whiteColor--text"
              ><router-link class="whiteColor--text" :to="{ name: 'home' }"
                >home</router-link
              ></v-list-item-title
            >
          </v-list-item>

          <v-list-item class="py-3">
            <v-list-item-title class="whiteColor--text"
              ><router-link class="whiteColor--text" :to="{ name: 'team' }"
                >Team</router-link
              ></v-list-item-title
            >
          </v-list-item>

          <v-list-item class="py-3">
            <v-list-item-title class="whiteColor--text"
              ><router-link class="whiteColor--text" :to="{ name: 'conOfMind' }"
                >Convergence of Mind</router-link
              ></v-list-item-title
            >
          </v-list-item>

          <v-list-item class="py-3">
            <v-list-item-title class="whiteColor--text"
              ><router-link class="whiteColor--text" :to="{ name: 'workshops' }"
                >Workshops</router-link
              ></v-list-item-title
            >
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
export default {
  name: "navBar",
  components: {},
  data: () => ({
    drawer: false,
    group: null,
  }),
  watch: {
    group() {
      this.drawer = false;
    },
  },
};
</script>

<style lang="scss">
.navBar {
  width: 100%;
  z-index: 99999999999999999999999999999999999999;
}
.navBar .v-btn > .v-btn__content .v-icon {
  color: #0fb89b !important;
}
</style>
