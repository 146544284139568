const noTags = /<\/?[\w\s]*>|<.+[\W]>/g;

export const validation = {
  /* validation*/
  emailRules: [
    (value: any) => !!value || "Required",
    (value: any) => (value || "").length <= 50 || "Max 50 characters",
    (value: any) => {
      const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return value ? pattern.test(value) || "Invalid email." : false;
    },
    // (value: any) =>
    //   value
    //     ? (!value.includes("yahoo") && !value.includes("gmail")) ||
    //     "Must be a bussiness email!"
    //     : false,
  ],
  required: [(value: any) => !!value || "required"],

  requiredNum: [
    (value?: number) => !!value || "number is required",
    (value: number) =>
      (value > 0) || "number is required",
    (value?: string) => {
      const pattern = noTags;
      return value ? !(pattern.test(value)) || "Invalid input!" : false;
    },
  ],
};
