var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',{ref:"applyForm",staticClass:"applyForm",model:{value:(_vm.Valid),callback:function ($$v) {_vm.Valid=$$v},expression:"Valid"}},[_c('v-card',{staticClass:"darkColor pa-3",attrs:{"dark":"","max-width":"1400","elevation":"10"}},[_c('v-card-title',{staticClass:"whiteColor--text mx-0 px-0 text-h5 pl-8"},[_vm._v(" "+_vm._s(_vm.tribType)),(_vm.tribName)?_c('span',{staticClass:"mx-1",class:_vm.tribcolor},[_vm._v("("+_vm._s(_vm.tribName)+")")]):_vm._e()]),_c('v-divider'),_c('v-card-text',[_c('v-stepper',{staticClass:"darkColor",model:{value:(_vm.el),callback:function ($$v) {_vm.el=$$v},expression:"el"}},[_c('v-stepper-items',_vm._l((_vm.tribType == 'Life Tribe'
              ? _vm.questions.filter(function (x) { return x.trib == 'life' || x.trib == 'all'; })
              : _vm.questions.filter(function (x) { return x.trib == 'business' || x.trib == 'all'; })),function(ques,i){return _c('v-stepper-content',{key:((i + 1) + "-content"),attrs:{"step":i + 1}},[_c('v-card',{staticClass:"mb-2",attrs:{"color":"darkColor","min-height":"200px","flat":""}},[_c('p',{staticClass:"text-h6"},[_c('v-avatar',{attrs:{"size":"27","color":"subColor"}},[_vm._v(_vm._s(i + 1))]),_vm._v(" - "+_vm._s(ques.qeus)+" ")],1),_c('p',{staticClass:"text-body-2 subColor--text"},[_vm._v(_vm._s(ques.desc))]),_c('v-divider',{staticClass:"my-5"}),(ques.choices.length)?_c('v-radio-group',{attrs:{"row":"","mandatory":""},model:{value:(ques.answer),callback:function ($$v) {_vm.$set(ques, "answer", $$v)},expression:"ques.answer"}},_vm._l((ques.choices),function(choic){return _c('v-radio',{key:choic + ques.num,staticClass:"mx-10 my-3 font-weight-bold",attrs:{"label":choic,"color":"subColor","value":choic,"rules":_vm.validation.required}})}),1):_vm._e(),(
                  ques.type == 'text' || ques.type == 'number' || ques.type == 'email'
                )?_c('div',{staticClass:"d-flex justify-center"},[_c('v-card',{staticClass:"darkColor",attrs:{"flat":"","min-width":"500","rounded":"xl"}},[_c('v-textarea',{attrs:{"placeholder":"type your answer here ...","filled":"","rounded":"","counter":"","clearable":"","dense":"","rows":"2","rules":ques.type == 'email'
                        ? _vm.validation.emailRules
                        : ques.type == 'number'
                        ? _vm.validation.requiredNum
                        : _vm.validation.required},model:{value:(ques.answer),callback:function ($$v) {_vm.$set(ques, "answer", $$v)},expression:"ques.answer"}})],1)],1):_vm._e()],1),_c('v-snackbar',{staticClass:"mb-10 font-weight-bold",attrs:{"bottom":"","timeout":"3000","rounded":"pill","color":_vm.success ? 'subColor' : 'error'},model:{value:(_vm.response),callback:function ($$v) {_vm.response=$$v},expression:"response"}},[(_vm.success)?_c('v-icon',{staticClass:"mx-3"},[_vm._v("mdi-check")]):_vm._e(),(_vm.error)?_c('v-icon',{staticClass:"mx-3"},[_vm._v("mdi-alert-circle-outline")]):_vm._e(),_vm._v(" "+_vm._s(_vm.text)+" ")],1)],1)}),1)],1),(_vm.notValid)?_c('p',{staticClass:"error--text mt-3 font-weight-bold"},[_vm._v(" Please review your answers, Some questions need to be answered ... ")]):_vm._e(),_c('div',{staticClass:"text-center mt-5"},[_c('v-pagination',{staticClass:"font-weight-bold",attrs:{"length":_vm.tribType == 'Life Tribe'
              ? _vm.questions.filter(function (x) { return x.trib == 'life' || x.trib == 'all'; }).length
              : _vm.questions.filter(function (x) { return x.trib == 'business' || x.trib == 'all'; }).length,"total-visible":7},model:{value:(_vm.el),callback:function ($$v) {_vm.el=$$v},expression:"el"}})],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"font-weight-bold",attrs:{"rounded":"","color":"subColor mx-3 px-5","loading":_vm.applyLoading},on:{"click":function($event){return _vm.apply()}}},[_vm._v(" Apply ")]),_c('v-btn',{staticClass:"font-weight-bold",attrs:{"outlined":"","rounded":"","color":"subColor"},on:{"click":function($event){_vm.dialoges.applyFormDialog = false}}},[_vm._v(" close ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }