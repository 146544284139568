import Vue from "vue";

export default Vue.extend({
    name: "conOfMind",
    data: () => ({
        conHelpers: [
            {
                title: `Leadership <br /> Mastery`,
                brief: "A leader is a person others will follow to a place they wouldn’t go alone! Developing the abilities to inspire, uplift, motivate and impact your team will literally set you free. Only inspired individuals thrive during times of crisis.  You’ll learn… and experience a new level of leadership!",
                path: "Group270",
                textColor: "lightOrange--text"
            },
            {
                title: `Mind <br /> Mastery`,
                brief: "At a The Convergence of Minds,” everyone has an exceptional set of skills, experiences, and connections. We will raise our standards by challenging each other to discover new levels of awareness that will allow us to discover new life and business paradigms. In addition, you’ll extend your network with people who are really interested in supporting you and your business to be the very best.",
                path: "Group271",
                textColor: "darkOrange--text"
            },
            {
                title: `Body <br /> Mastery`,
                brief: "The New Alpha gives his team and himself the permission to re-prioritize health and wellness to achieve success. At the Convergence of Minds, we believe that wellness is a missing Leadership Competency, and we will help you acquire it. ",
                path: "Group266",
                textColor: "lightBlue--text"
            },
            {
                title: `Get Access<br />to World- Class<br />Experts & Expertise`,
                brief: " Whether you are looking for business growth or personal growth, your network does matter, whether it is the right coach, trainer, founder, marketer, VC, or even lawyer; Knowing the right people saves you a lot of time, money and help you dodge expensive mistakes. ",
                path: "Group267",
                textColor: "lightPurple--text"
            },
            {
                title: `Focus on <br /> What Matters`,
                brief: "Surrounding yourself with the right people will help you stay laser-focused on the things that will truly develop your life and business because It is easy to get overwhelmed and be lost in the jungle of distractions and lose your way. ",
                path: "Group269",
                textColor: "greenColor--text"
            }
        ]
    }),
});
