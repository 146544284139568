/* src/plugins/vuetify.js*/

import Vue from "vue";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    dark: false,
    themes: {
      light: {
        lightColor: "#699DEE",
        subColor: "#0FB89B",
        whiteColor: "#fff",
        darkColor: "#212E3C",
        calender:"#2A3B4D",

        greenColor: "#0FB89B",
        lightBlue: "#699DEE",
        lightOrange: "#FDB846",
        darkOrange: "#F05C45",
        purpleColor: "#3D437E",
        lightPurple: "#8A74EA",
      },
    },
  },
  treeShake: true,
});



