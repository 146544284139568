import Vue from "vue";
import applyForm from "../../components/applyForm.vue";



export default Vue.extend({
    name: "home",
    props: ["workshops"],
    components: {
        applyForm,
    },
    data: () => ({
        date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
            .toISOString()
            .substr(0, 10),
        vedioDialoge: false,
        Schedule: false,
        loading: false,
        dialoges: {
            applyFormDialog: false,
        },
        tribType: "",
        tribeTybes: [
            {
                title1: "Busi",
                title2: "ness Tribe",
                desc: "Accelerate Your Business Growth",
                mainImg: "business",
                subImg: "Path28531",
                apply: "apply1"
            },
            {
                title1: "Life",
                title2: "Tribe",
                desc: "Transcend Your Wellbeing",
                mainImg: "life",
                subImg: "Path28532",
                apply: "apply2"
            },
        ],
        businessCards: [
            {
                img: "Group253",
                ext: "webp",
                subImg: "Group216",
                title: "The Explorers’",
                text1: "Online",
                text2: "Monthly",
                text3: "4 hours",
                text4: "1:1 Coaching Sessions",
                tribeId: "explorers",
            },
            {
                img: "Group254",
                subImg: "Group217",
                title: "The Adventurers’",
                text1: "Hybrid",
                text2: "Monthly",
                text3: "4 hours online / 8 hours live",
                text4: "1:1 Coaching Sessions",
                tribeId: "adventurers",
            },
            {
                img: "Group290",
                subImg: "Group218",
                title: "The Campaigners’",
                text1: "Live",
                text2: "Monthly",
                text3: "24 hours",
                text4: "1:1 Coaching Sessions",
                tribeId: "campaigners",
            },
        ],
        lifeCards: [
            {
                img: "blue",
                subImg: "Group120",
                title: "For you",
                text1: "Online",
                text2: "Monthly",
                text3: "4 hours",
                text4: "1:1 Coaching Sessions",
                tribeId: "single",
            },
            {
                img: "Group255",
                subImg: "Group120",
                title: "For your family",
                text1: "Online",
                text2: "Monthly",
                text3: "4 hours online / 8 hours live",
                text4: "1:1 Coaching Sessions",
                tribeId: "family",
            },
        ],
        rules: [
            {
                color: "lightOrange",
                title: "Think Big (COM)",
                titleColor: "lightOrange--text",
                text:
                    "At Convergence of minds, we'll literally expand your Horizons! You'll be stretched beyond your boundaries to explore new possibilities.",
                //text: "No guessing allowed! We don’t just sit around talking about untested theories; the Convergence of Mind is all about real people and real experiences."
            },
            {
                color: "greenColor",
                title: "Be Present",
                titleColor: "greenColor--text",
                text:
                    "COM focuses on helping those who want to transform their lives and achieve big dreams, thus there is no place for time wasters. If you are not serious, don't apply.",
                //text: "You are REQUIRED To Share Your Victories as well as your Embarrassing Failures in detail. You must be willing to share. If this troubles you, please don’t apply."
            },
            {
                color: "lightPurple",
                title: "Execute Execute Execute ... ",
                titleColor: "lightPurple--text",
                text:
                    "More importantly, you must be willing to execute the fantastic ideas you will hear and learn here because we will take your feedback and learn more from your experience.",
                //text: "More importantly, you must be willing to EXECUTE on the fantastic ideas you hear because we will take your feedback on the execution; if this does not make you feel comfortable, please don’t apply."
            },
        ],
        smRules: [
            {
                img: "ruleOne",
                title: "BE Present",
                text: `Only 10% absence is <br />allowed`,
            },
            {
                img: "ruleTwo",
                title: "Share",
                text: `We can only help each<br /> other if we trust <br /> each other`,
            },
            {
                img: "ruleThree",
                title: "Act",
                text: `Listening is not enough, <br />take action`,
            },
            {
                img: "ruleFour",
                title: "Stick To It",
                text: `Commitment is a Key <br /> to success`,
            },
            {
                img: "ruleFive",
                title: "Success",
                text: `Is why you are here`,
            },
        ],
        slideImgs: [
            "img1", "img2", "img3", "img4", "img5", "img6", "img7", "img8", "img9", "img10", "img11"
        ],
        model: null,
    }),
    methods: {
        openApplyDialoge(type: string) {
            this.dialoges.applyFormDialog = true;
            this.tribType = type;

        },
        goToWorkshops: async function () {
            try {
                this.$router.push("workshops");
            } catch (error) {
                console.log(error);
            }
        },
        goToTribe(id: any) {
            let that = this;
            that.goToWorkshops().then(() => {
                that.workshops.tribeSection = id;
            });
        },
    },
});
