import Vue from "vue";
import applyForm from "../../components/applyForm.vue";

export default Vue.extend({
    name: "workshops",
    components: {
        applyForm,
    },
    props: ["workshops"],
    data: () => ({
        dialoges: {
            applyFormDialog: false,
        },
        tribs: [
            {
                category: "Business Tribe",
                tribeId: "explorers",
                color: "lightOrange",
                textColor: "lightOrange--text",
                name: "The Explorers’ Tribe",
                brief:
                    "The Explorers Tribe Will Work & Support You  To Answer The Following Questions",
                stebs: [
                    "What are the most important things to know before starting?",
                    "What are the competencies I should have?",
                    "What are the early challenges and how to overcome them? ",
                    "How to find the right start-up idea? ",
                    "How do I know if my product  / service is viable?",
                    "What is the Start-up language?",
                    "What are the 101 legal start-up requirements? ",
                    "How to finance my Start-up? ",
                    "What technology should I use ?",
                    "I want help with the platform, website, or App. Development?",
                    "I am looking for a Start-up coach?",
                    "How can I hold my accounting books at early stage? ",
                ],
                cards: [
                    {
                        path: "card1",
                        format: "Online",
                        attendees: "Once a week",
                        time: "4 hours",
                        liveEvents: "Once Quarterly",
                        extras: "Six Coaching Sessions, Business Documents Library and Basic Website",
                        price: "$20",
                    },
                    // {
                    //     path: "Path28500",
                    //     format: "Online",
                    //     attendees: "Once a week",
                    //     time: "4 hours",
                    //     liveEvents: "Once Quarterly",
                    //     extras: "Six Coaching Sessions, Business Documents Library and Basic Website",
                    //     price: "$47",
                    // },
                ],

                button: "Group280",
            },
            {
                category: "Business Tribe",
                tribeId: "adventurers",
                color: "darkOrange",
                textColor: "darkOrange--text",
                name: "The Adventurers’ Tribe",
                brief:
                    "The Adventurers Tribe Will Work & Support You  To Answer The Following Questions  This Is Your Tribe",
                stebs: [
                    "What is the best business model I should use?",
                    "How to hire my team? How to work as a team?",
                    "How to create product market fit?",
                    "How to start create my sales pipeline? And get more traffic? ",
                    "How plan my daily team activities and set objectives",
                    "What are the Key Performance Indicators that matter now?",
                    "How to brand my Start-up?",
                    "Price or quality at this stage?",
                    "What is the best way to develop my investor pitch deck?",
                    "Do I need an Angel investor? How to find an angel investors? ",
                    "How to build my financial model?",
                    "How set-up and distribute shares?",
                ],
                cards: [
                    {
                        path: "card2",
                        format: "Hybrid",
                        attendees: "Weekly",
                        time: "3 Times – 4 hours",
                        liveEvents: "Once Quarterly",
                        extras: "12 Coaching Sessions, Business Documents Library and Branding Strategy",
                        price: "$118",
                    },
                    // {
                    //     path: "Path28504",
                    //     format: "Hybrid",
                    //     attendees: "Weekly",
                    //     time: "3 Times – 4 hours",
                    //     liveEvents: "Once Quarterly",
                    //     extras: "12 Coaching Sessions, Business Documents Library and Branding Strategy",
                    //     price: "$156",
                    // },
                ],
                button: "Group273",
            },
            {
                category: "Business Tribe",
                tribeId: "campaigners",
                color: "greenColor",
                textColor: "greenColor--text",
                name: "The Campaigners’ Tribe",
                brief:
                    "The Campaigners’ Tribe Will Work & Support You  To Answer The Following Questions",
                stebs: [
                    "How to double or triple my business revenues? ",
                    "How to find the right consultants for my business? ",
                    "I am looking for a personal business mentor?",
                    "How to craft an implementable and practical business strategy?",
                    "How to network with successful businessmen and entrepreneurs?",
                    "Does scaling include cutting down on activities?",
                    "How to build my personal & Business brand?",
                    "How to increase my evaluation?",
                    "Understanding Financial models?",
                    "How to exit your business ? Or pivot?",
                    "I want to keep updated with all the trending technologies?",
                    "How to create a referral network?",
                ],
                cards: [
                    {
                        path: "card3",
                        format: "Live",
                        attendees: "Monthly",
                        time: "3 Days Event – 8 hours / day",
                        liveEvents: "-",
                        extras: "12 Coaching Sessions, Business Documents Library, 50% on our Business Diagnostic Systems and 6 days training for your team",
                        price: "$300",
                    },
                    // {
                    //     path: "Path28510",
                    //     format: "Live",
                    //     attendees: "Monthly",
                    //     time: "3 Days Event – 8 hours / day",
                    //     liveEvents: "-",
                    //     extras: "12 Coaching Sessions, Business Documents Library, 50% on our Business Diagnostic Systems and 6 days training for your team",
                    //     price: "$350",
                    // },
                ],
                button: "Group274",
            },
            {
                category: "Life Tribe",
                tribeId: "single",
                color: "lightBlue",
                textColor: "lightBlue--text",
                name: "For you",
                brief:
                    "Setting our aim too high and falling short is not dangerous, but setting our aim too low, and achieving it, is.",
                stebs: [
                    "Self-awareness & understanding why you do what you do",
                    "Change your limiting beliefs and cleanse your negative emotions",
                    "Build a strong mindset, and create lasting change",
                    "Create compelling future, Align your desires, dreams and purpose",
                    "Plan for what really matters to you",
                    "Design your own romantic, physical, financial, social and spiritual wellbeing",
                ],
                cards: [
                    {
                        path: "card4",
                        format: "Online",
                        attendees: "monthly",
                        time: "4 hours",
                        liveEvents: "NA",
                        extras: "Six Coaching Sessions ",
                        price: "$20",
                    },
                    // {
                    //     path: "Path28516",
                    //     format: "Hybrid",
                    //     attendees: "For Two",
                    //     time: "4 hours",
                    //     liveEvents: "4 Live sessions",
                    //     extras: "Six Coaching Sessions ",
                    //     price: "$47",
                    // },
                ],
                button: "Group275",
            },
            {
                category: "Life Tribe",
                tribeId: "family",
                color: "lightPurple",
                textColor: "lightPurple--text",
                name: "For your family",
                brief:
                    "Setting our aim too high and falling short is not dangerous, but setting our aim too low, and achieving it, is.",
                stebs: [
                    "Develop your emotion intelligence as spouses and parents",
                    "Navigate through most common relationship obstacles",
                    "Apply parenting with emotional intelligence",
                    "Understand and manage your impulsive reactions",
                    "Inspire your kids to be more resilient and self-motivated",
                    "Establish a positive parent–child bond based on teaching not punishing.",
                ],
                cards: [
                    {
                        path: "card5",
                        format: "Online",
                        attendees: "monthly",
                        time: "4 hours",
                        liveEvents: "NA",
                        extras: "Six Coaching Sessions ",
                        price: "$20",
                    },
                    // {
                    //     path: "Path28521",
                    //     format: "Hybrid",
                    //     attendees: "For Two",
                    //     time: "4 hours",
                    //     liveEvents: "4 Live sessions",
                    //     extras: "Six Coaching Sessions ",
                    //     price: "$47",
                    // },
                ],
                button: "Group276",
            },
        ],
        tribType: "",
        tribName: "",
        tribcolor: ""
    }),

    methods: {
        openApplyDialoge(type: string, name: string, color: string) {
            this.dialoges.applyFormDialog = true;
            this.tribType = type;
            this.tribName = name;
            this.tribcolor = color;
        }
    }
});
