<template>
  <div class="d-flex">
    <v-row class="ma-0 pa-0">
      <v-col cols="12" md="7" class="ma-0 pa-0">
        <v-card
          class="py-5 calender rounded-0 d-flex justify-center whiteColor--text font-weight-bold"
          width="100%"
          height="100%"
        >
          copyright<v-icon class="whiteColor--text mx-1 font-weight-bold"
            >mdi-copyright</v-icon
          >2022
          <a class="whiteColor--text mx-2 font-weight-bold" href="https://6bees.co/"
            >6bees.co</a
          >
        </v-card>
      </v-col>

      <v-col cols="12" md="5" class="ma-0 pa-0">
        <v-card class="py-5 greenColor rounded-0" width="100%" height="100%"></v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
const axios = require("axios");
import * as validation from "@/global/validation.ts";
export default {
  name: "footerLayout",
  components: {},
  data: () => ({
    email: "",
    loading: false,
    // validation
    validation: validation.validation,
    Valid: false,
    newobj: {
      email: "",
      notMailer: true,
    },
    // snackbar
    error: false,
    errText: "",
    timeout: 2000,
    success: false,
  }),
  methods: {
    // addEmail() {
    //   if (this.$refs.emailForm.validate()) {
    //     axios({
    //       method: "POST",
    //       url: "https://localhost:44329/api/Competencies/PostCompetency",
    //       data: this.email,
    //       headers: {
    //         "Content-Type": "application/json",
    //       },
    //     })
    //       .then((response) => {
    //         console.log(response);
    //       })
    //       .catch((error) => {
    //         console.log(error);
    //       });
    //   }
    // },

    addEmail() {
      let docObj = Object.assign({}, this.newobj);
      if (this.$refs.emailForm.validate()) {
        this.loading = true;
        axios({
          method: "POST",
          url:
            "https://landlord-bl-staging.azurewebsites.net/api/sixBeesEmailsCollector/createEmail",
          data: docObj,
          headers: {
            "Content-Type": "application/json",
          },
        })
          .then((response) => {
            if (response.data.ok) {
              this.success = true;
              this.error = false;
              this.loading = false;
            } else {
              this.success = false;
              this.loading = false;
              this.errText = response.data.errorMessage;
              console.log(response.data.errorMessage);
              this.error = true;
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
  },
};
</script>
