import Vue from "vue";


export default Vue.extend({
  name: "team",
  data: () => ({
    team: [
      // youssry essmatt
      {
        name: "Meet Youssry Essmatt",
        title1: "Senior",
        title2: " consultant",
        img: "youssry",
        border: "border-bottom: 2px solid #0fb89b",
        brief: "Entrepreneur, Consultant & Coach with 25+ years of experience working with C class leaders in both government and private sector, developing organizational performance and change management interventions.  Youssry has worked for organizations such as Korn Ferry, Oxford Group. ILM and led complex performance & Strategy projects for organizations such as  Pfizer, Vodafone, Etisalat, MARs, STC, AL Rajhi Bank, Ministry of Justice, Ministry of Finance and much more. Youssry has an MBA in Change Management from Wolverhampton University, UK",
        eval: [
          {
            text: "Transformation",
            number: "100%",
            prog: "progress1"
          },
          {
            text: "Strategy",
            number: "100%",
            prog: "progress2"
          },
          {
            text: "Energetic",
            number: "100%",
            prog: "progress3"
          },
          {
            text: "Leadership",
            number: "100%",
            prog: "progress4"
          }
        ]
      },
      // lama
      {
        name: "Meet Lama Herzallah",
        title1: "Senior",
        title2: " consultant",
        img: "lama",
        border: "border-bottom: 2px solid #F05C45",
        brief: "Long experience of over 23 years in brand management and business development, working with multinational organizations, leading and inspiring teams before she moves in the world of Learning and Development. Her organizational skills and natural charisma make her an excellent coach and motivator for people seeking to achieve their goals,shows the way to bridge the gap between their current circumstances and the life they would like to lead in life/ work & relationships.",
        eval: [
          {
            text: "Management",
            number: "100%",
            prog: "progress1"
          },
          {
            text: "Coaching",
            number: "100%",
            prog: "progress2"
          },
          {
            text: "Personal branding",
            number: "100%",
            prog: "progress3"
          },
          {
            text: "Influencer",
            number: "100%",
            prog: "progress4"
          }
        ]
      },
      //jack
      {
        name: "Meet Jack",
        title1: "Senior",
        title2: " consultant",
        img: "jack",
        border: "border-bottom: 2px solid #FDB846",
        brief: "25+ years of experience in creating software solutions through business development lenses, using several technologies and across several platforms. Jack also filled every position building information system, from analyses and design to coding and testing, training and sales, and consultancy.   Jack led several mega IT Projects for Dubai Public Prosecution where he developed their entire internal prosecution system as well as their web portal. Jack also developed for Emirates National ID Solution their system that connects the public to the government database. Jack has a bachelor of science in Management Information Systems",
        eval: [
          {
            text: "Interpriorship",
            number: "100%",
            prog: "progress1"
          },
          {
            text: "IT",
            number: "100%",
            prog: "progress2"
          },
          {
            text: "IOT",
            number: "100%",
            prog: "progress3"
          },
          {
            text: "Risk taker",
            number: "100%",
            prog: "progress4"
          }
        ]
      },
      // Nabil Senyonga
      {
        name: "Meet Nabil Senyonga",
        title1: "Senior",
        title2: " consultant",
        img: "nabil",
        border: "border-bottom: 2px solid #699DEE",
        brief: "During his 25+ years he has held senior leadership positions in L&D with leading hospitality groups and has been consulting clients in diverse industries. Nabil has extensive experience in Leadership and Management Development as well as building effective teams. Nabil has worked for organizations such as Marriott, Hilton, and Emaar  and as a consultant for STC, Abu Dhabi Executive Council, Alinma Bank, Total and much more. Nabil Holds MBA in Marketing From Washington University.",
        eval: [
          {
            text: "Energetic",
            number: "100%",
            prog: "progress1"
          },
          {
            text: "learning & development",
            number: "100%",
            prog: "progress2"
          },
          {
            text: "Customer service",
            number: "100%",
            prog: "progress3"
          },
          {
            text: "Sincere",
            number: "100%",
            prog: "progress4"
          }
        ]
      },

      // Feras Mohammed
      {
        name: "Meet Feras Mohammed",
        title1: "Senior",
        title2: " consultant",
        img: "feras",
        border: "border-bottom: 2px solid #FDB846",
        brief: "25+ Years of Experience in the domain of Organizational Development and Strategy, Feras comes as a Subject Mater Expert who leads the implementation team. Feras has worked with C-level executive and held many senior leadership roles with leading organizations such as Al Tayer Group,  Kanebo Japan, Dubai World Trade Centre and as a consultant for Tetra Pak, Total, SAIB Bank, DIB, and Many others. Feras is certified innovation and change PR actioner as well as assessment Manager from SHL and has a bachelor degree in political Science from the American University in Cairo.",
        eval: [
          {
            text: "Energetic",
            number: "100%",
            prog: "progress1"
          },
          {
            text: "learning & development",
            number: "100%",
            prog: "progress2"
          },
          {
            text: "Customer service",
            number: "100%",
            prog: "progress3"
          },
          {
            text: "Sincere",
            number: "100%",
            prog: "progress4"
          }
        ]
      },
      //Carol Talbot
      // {
      //   name: "Meet Carol Talbot",
      //   title1: "Senior",
      //   title2: " consultant",
      //   img: "carol",
      //   border: "border-bottom: 2px solid #8A74EA",
      //   brief: "25+ Years of Experience in the domain of Organizational Development and Strategy, Feras comes as a Subject Mater Expert who leads the implementation team. Feras has worked with C-level executive and held many senior leadership roles with leading organizations such as Al Tayer Group,  Kanebo Japan, Dubai World Trade Centre and as a consultant for Tetra Pak, Total, SAIB Bank, DIB, and Many others. Feras is certified innovation and change PR actioner as well as assessment Manager from SHL and has a bachelor degree in political Science from the American University in Cairo",
      //   eval: [
      //     {
      //       text: "Curious",
      //       number: "80%",
      //       prog: "Group259"
      //     },
      //     {
      //       text: "Energetic",
      //       number: "80%",
      //       prog: "Group261"
      //     },
      //     {
      //       text: "Motivated",
      //       number: "100%",
      //       prog: "Group262"
      //     },
      //     {
      //       text: "Fun to work",
      //       number: "80%",
      //       prog: "Group260"
      //     }
      //   ]
      // },


    ]
  })
});
